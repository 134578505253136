// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Enums
import { AppointmentType } from "../enums/appointment-type.enum";

@Pipe({
    name: "appointment"
})
export class AppointmentTypePipe implements PipeTransform {

    transform(value: AppointmentType): string {
        switch (value) {
            default:
            case AppointmentType.general:
                return "appointment-type-pipe.general";
            case AppointmentType.birthday:
                return "appointment-type-pipe.birthday";
            case AppointmentType.pressConference:
                return "appointment-type-pipe.press-conference";
            case AppointmentType.match:
                return "appointment-type-pipe.match";
        }
    }
}
