// Framework
import { Component, OnInit } from "@angular/core";
// Services
import { CookieConsentBannerService } from "./modules/cookie/services/cookie-consent-banner.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    title = "ahzsmb-frontend-ng";

    constructor(private cookieConsentBannerService: CookieConsentBannerService) { }

    ngOnInit(): void {
        this.cookieConsentBannerService.initBanner();
    }
}
