// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Enums
import { AllowedMessageCategories } from "../enums/allowed-message-categories";

@Pipe({
    name: "category"
})
export class CategoryPipe implements PipeTransform {

    transform(value: AllowedMessageCategories): any {
        switch (value) {
            case AllowedMessageCategories.news:
                return "category-pipe.news";
            case AllowedMessageCategories.playerOfTheMatch:
                return "category-pipe.potm";
            case AllowedMessageCategories.liveticker:
                return "category-pipe.liveticker";
            case AllowedMessageCategories.appointments:
                return "category-pipe.appointment";
            default:
                return null;
        }
    }
}
